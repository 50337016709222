<template>
  <vue-final-modal
    v-slot="{ params, close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-on="$listeners"
  >
    <div class="modal__title">
      <slot name="title"></slot>
    </div>
    <div class="modal__content">
      <slot name="content"></slot>
    </div>
    <div class="modal__action">
      <slot name="actions"></slot>
    </div>
    <div class="modal__close" @click="close">
      <span class="material-icons">close</span>
    </div>
  </vue-final-modal>
</template>
<script>
export default {
  name: "CustomModal",
  inheritAttrs: false,
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 10rem;
  padding: 5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.modal__content {
  flex-grow: 1;
  overflow-y: auto;
  text-align: center;
  margin: 15px 0px;
}
.modal__action {
  flex-shrink: 0;
  padding: 1rem 0 0;
  margin: 0.2rem 5rem;
}
.modal__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
